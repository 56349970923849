import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@material-ui/core';
import CurrencyDollar from '../../icons/CurrencyDollar';
import AuthContext from '../../contexts/JWTContext';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import CustomLogout from './CustomLogout';

const DashboardSidebar = (props) => {
  const [sections] = React.useState([]);

  useEffect(() => {
    sections.push({
      title: 'Management',
      items: [
        {
          title: 'Purchases',
          path: '/dashboard/purchases',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: 'Customers',
          path: '/dashboard/customers',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: 'B2B2C',
          path: '/dashboard/b2b2c',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: 'Ops Team',
          path: '/dashboard/customers/ops-view',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: 'Sales Team',
          path: '/dashboard/customers/leads-view',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: 'Insurance Consults',
          path: '/dashboard/customers/upcoming-insurance-consults',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: 'Naltrexone Refills',
          path: '/dashboard/customers/upcoming-nax-refills',
          icon: <UsersIcon fontSize="small" />,
        },
      ]
    });

    sections.push({
      title: 'Payments',
      items: [
        {
          title: 'Plans',
          path: '/dashboard/nmi/plans',
          icon: <CurrencyDollar fontSize="small" />,
        },
        {
          title: 'Coupons',
          path: '/dashboard/nmi/coupons',
          icon: <CurrencyDollar fontSize="small" />,
        },
        {
          title: 'Order',
          path: '/dashboard/orders',
          icon: <CurrencyDollar fontSize="small" />,
        },
        {
          title: 'Collections',
          path: '/dashboard/collections',
          icon: <CurrencyDollar fontSize="small" />,
        },
      ]
    });
  }, []);

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, isInitialized } = useContext(AuthContext);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location]);
  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 1
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 32,
                width: 32
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ margin: 'auto' }}
            >
              {`${user.firstname} ${user.lastname}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 1 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 1
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ ml: 1, my: 1 }}>
          <CustomLogout />
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 240
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 240
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
