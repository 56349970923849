import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import InsuranceEligibilityEditForm from './components/dashboard/customer/InsuranceEligibilityEditForm';
import InsuranceDetailsEditForm from './components/dashboard/customer/InsuranceDetailsEditForm';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const EHRQueueLog = Loadable(lazy(() => import('./pages/dashboard/EHRQueueLog')));
const EHROverview = Loadable(lazy(() => import('./pages/dashboard/EHROverview')));
const EHRPatientsList = Loadable(lazy(() => import('./pages/dashboard/EHRPatientsList')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerGenerateCheckout = Loadable(lazy(() => import('./pages/dashboard/CustomerGenerateCheckout')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const B2B2C = Loadable(lazy(() => import('./pages/dashboard/B2B2C')));
const CustomerChat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const OpsView = Loadable(lazy(() => import('./pages/dashboard/OpsView')));
const LeadsView = Loadable(lazy(() => import('./pages/dashboard/LeadsKanban')));
const AllCustomerList = Loadable(lazy(() => import('./pages/dashboard/AllCustomerList')));
const BIOrders = Loadable(lazy(() => import('./pages/dashboard/BIOrders')));
const BIFacebook = Loadable(lazy(() => import('./pages/dashboard/BIFacebook')));
const EHRPatientsQueue = Loadable(lazy(() => import('./pages/dashboard/EHRPatientsQueue')));
const EHRAppointmentsQueue = Loadable(lazy(() => import('./pages/dashboard/EHRAppointmentsQueue')));
const EHRNotesQueue = Loadable(lazy(() => import('./pages/dashboard/EHRNotesQueue')));
const EHRMemosQueue = Loadable(lazy(() => import('./pages/dashboard/EHRMemosQueue')));
const WalnutPlans = Loadable(lazy(() => import('./pages/dashboard/WalnutPlans')));
const WalnutPlanDetails = Loadable(lazy(() => import('./pages/dashboard/WalnutPlanDetails')));
const WalnutPlanEdit = Loadable(lazy(() => import('./pages/dashboard/WalnutPlanEdit')));
const WalnutPlanCreate = Loadable(lazy(() => import('./pages/dashboard/WalnutPlanCreate')));
const WalnutCoupons = Loadable(lazy(() => import('./pages/dashboard/WalnutCoupons')));
const WalnutCouponDetails = Loadable(lazy(() => import('./pages/dashboard/WalnutCouponDetails')));
const WalnutCouponEdit = Loadable(lazy(() => import('./pages/dashboard/WalnutCouponEdit')));
const WalnutCouponCreate = Loadable(lazy(() => import('./pages/dashboard/WalnutCouponCreate')));
const WalnutBills = Loadable(lazy(() => import('./pages/dashboard/WalnutBills')));
const WalnutBillDetails = Loadable(lazy(() => import('./pages/dashboard/WalnutBillDetails')));
const WalnutBillRefund = Loadable(lazy(() => import('./pages/dashboard/WalnutBillRefund')));
const NMICollections = Loadable(lazy(() => import('./pages/dashboard/NMICollections')));
const NMIPlans = Loadable(lazy(() => import('./pages/dashboard/NMIPlans')));
const NMIPlanDetails = Loadable(lazy(() => import('./pages/dashboard/NMIPlanDetails')));
const NMIPlanEdit = Loadable(lazy(() => import('./pages/dashboard/NMIPlanEdit')));
const NMIPlanCreate = Loadable(lazy(() => import('./pages/dashboard/NMIPlanCreate')));
const NMICoupons = Loadable(lazy(() => import('./pages/dashboard/NMICoupons')));
const NMICouponDetails = Loadable(lazy(() => import('./pages/dashboard/NMICouponDetails')));
const NMICouponEdit = Loadable(lazy(() => import('./pages/dashboard/NMICouponEdit')));
const NMICouponCreate = Loadable(lazy(() => import('./pages/dashboard/NMICouponCreate')));
const NMIOrders = Loadable(lazy(() => import('./pages/dashboard/NMIOrders')));
const NMIOrderDetails = Loadable(lazy(() => import('./pages/dashboard/NMIOrderDetails')));
const NMIOrderRefund = Loadable(lazy(() => import('./pages/dashboard/NMIOrderRefund')));
const SquareupOrders = Loadable(lazy(() => import('./pages/dashboard/SquareupOrders')));
const SquareupPlans = Loadable(lazy(() => import('./pages/dashboard/SquareupPlans')));
const StripeOrders = Loadable(lazy(() => import('./pages/dashboard/StripeOrders')));
const StripePlans = Loadable(lazy(() => import('./pages/dashboard/StripePlans')));
const StripeCoupons = Loadable(lazy(() => import('./pages/dashboard/StripeCoupons')));
const UpcomingInsuranceConsults = Loadable(lazy(() => import('./pages/dashboard/UpcomingInsuranceConsults')));
const UpcomingNaxRefills = Loadable(lazy(() => import('./pages/dashboard/UpcomingNaxRefills')));
const PurchasesList = Loadable(lazy(() => import('./pages/dashboard/PurchasesList')));
// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <CustomerList />
      },
      {
        path: '/chat',
        element: <CustomerChat />
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />
          },
          {
            path: '/ops-view',
            element: <OpsView />
          },
          {
            path: '/upcoming-insurance-consults',
            element: <UpcomingInsuranceConsults />
          },
          {
            path: '/upcoming-nax-refills',
            element: <UpcomingNaxRefills />
          },
          {
            path: '/leads-view',
            element: <LeadsView />
          },
          {
            path: '/all',
            element: <AllCustomerList />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />
          },
          {
            path: ':customerId/insurance-eligibility/edit',
            element: <InsuranceEligibilityEditForm />
          },
          {
            path: ':customerId/insurance-details/edit',
            element: <InsuranceDetailsEditForm />
          },
          {
            path: ':customerId/generate-checkout',
            element: <CustomerGenerateCheckout />
          }
        ]
      },
      {
        path: 'b2b2c',
        children: [
          {
            path: '/',
            element: <B2B2C />
          },
        ]
      },
      {
        path: 'bi-orders',
        element: <BIOrders />
      },
      {
        path: 'bi-facebook',
        element: <BIFacebook />
      },
      {
        path: 'patients-sync-queue',
        children: [
          {
            path: '/',
            element: <EHRPatientsQueue />
          },
          {
            path: ':queType/:recordId',
            element: <EHRQueueLog />
          }]
      },
      {
        path: 'appointments-sync-queue',
        children: [
          {
            path: '/',
            element: <EHRAppointmentsQueue />
          },
          {
            path: ':queType/:recordId',
            element: <EHRQueueLog />
          }]
      },
      {
        path: 'notes-sync-queue',
        children: [
          {
            path: '/',
            element: <EHRNotesQueue />
          },
          {
            path: ':queType/:recordId',
            element: <EHRQueueLog />
          }]
      },
      {
        path: 'memos-sync-queue',
        children: [
          {
            path: '/',
            element: <EHRMemosQueue />
          },
          {
            path: ':queType/:recordId',
            element: <EHRQueueLog />
          }]
      },
      {
        path: 'ehr-patients',
        children: [
          {
            path: '/',
            element: <EHRPatientsList />
          }
        ]
      },
      {
        path: 'ehr-overview',
        children: [
          {
            path: '/',
            element: <EHROverview />
          }
        ]
      },
      {
        path: 'walnut/plans',
        children: [
          {
            path: '/',
            element: <WalnutPlans />
          },
          {
            path: ':planId',
            element: <WalnutPlanDetails />
          },
          {
            path: ':planId/edit',
            element: <WalnutPlanEdit />
          },
          {
            path: '/create',
            element: <WalnutPlanCreate />
          }
        ]
      },
      {
        path: 'walnut/coupons',
        children: [
          {
            path: '/',
            element: <WalnutCoupons />
          },
          {
            path: ':couponId',
            element: <WalnutCouponDetails />
          },
          {
            path: ':couponId/edit',
            element: <WalnutCouponEdit />
          },
          {
            path: '/create',
            element: <WalnutCouponCreate />
          }
        ]
      },
      {
        path: 'walnut/bills',
        children: [
          {
            path: '/',
            element: <WalnutBills />
          },
          {
            path: ':billId',
            element: <WalnutBillDetails />
          },
          {
            path: ':billId/refund',
            element: <WalnutBillRefund />
          }
        ]
      },
      {
        path: 'nmi/plans',
        children: [
          {
            path: '/',
            element: <NMIPlans />
          },
          {
            path: ':planId',
            element: <NMIPlanDetails />
          },
          {
            path: ':planId/edit',
            element: <NMIPlanEdit />
          },
          {
            path: '/create',
            element: <NMIPlanCreate />
          }
        ]
      },
      {
        path: 'nmi/coupons',
        children: [
          {
            path: '/',
            element: <NMICoupons />
          },
          {
            path: ':couponId',
            element: <NMICouponDetails />
          },
          {
            path: ':couponId/edit',
            element: <NMICouponEdit />
          },
          {
            path: '/create',
            element: <NMICouponCreate />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <NMIOrders />
          },
          {
            path: ':orderId',
            element: <NMIOrderDetails />
          },
          {
            path: ':orderId/refund',
            element: <NMIOrderRefund />
          }
        ]
      },
      {
        path: 'collections',
        children: [
          {
            path: '/',
            element: <NMICollections />
          }
        ]
      },
      {
        path: 'squareup/orders',
        children: [
          {
            path: '/',
            element: <SquareupOrders />
          }
        ]
      },
      {
        path: 'squareup/plans',
        children: [
          {
            path: '/',
            element: <SquareupPlans />
          }
        ]
      },
      {
        path: 'stripe/orders',
        children: [
          {
            path: '/',
            element: <StripeOrders />
          }
        ]
      },
      {
        path: 'stripe/coupons',
        children: [
          {
            path: '/',
            element: <StripeCoupons />
          }
        ]
      },
      {
        path: 'stripe/plans',
        children: [
          {
            path: '/',
            element: <StripePlans />
          }
        ]
      },
      {
        path: 'purchases',
        children: [
          {
            path: '/',
            element: <PurchasesList />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
