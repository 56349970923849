const axios = require('axios');

const defaultAxiosConfig = (accessToken) => ({
  headers: {
    Authorization: accessToken,
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
class CustomerApi {
  async getCustomers(offset = 0, limit = 10000, query = '', sortBy, sortDir, type, salesRepId, userSubStatus, ketaminePack, genPsychPack, traumaPack, naltrexonePack, showInsurancePatients = false) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    let queryParams = `offset=${offset}&limit=${limit}&type=${type}&searchValue=${query}&sortBy=${sortBy}&sortDir=${sortDir}`;
    if (salesRepId) {
      queryParams += `&filterBySaleRepId=${salesRepId}`;
    }
    if (userSubStatus) {
      queryParams += `&userSubStatus=${userSubStatus}`;
    }
    if (ketaminePack) {
      queryParams += `&ketaminePack=${ketaminePack}`;
    }
    if (genPsychPack) {
      queryParams += `&genPsych=${genPsychPack}`;
    }
    if (traumaPack) {
      queryParams += `&traumaPack=${traumaPack}`;
    }
    if (naltrexonePack) {
      queryParams += `&naltrexonePack=${naltrexonePack}`;
    }
    if (showInsurancePatients) {
      queryParams += `&insuranceCustomer=${showInsurancePatients}`;
    }
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/listV2?${queryParams}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    console.log('resp', resp.data);
    return resp.data;
  }

  async getCustomerNotifications(accessToken, customerId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/notifications/user/${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerFormSubmissions(customerId) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/forms?userId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSurvey(customerId) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/surveys/list?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig,
    );
    return resp.data;
  }

  async getInsuranceCollectionsList(daysToAppointment = 20) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/insurance/collections?daysToAppointment=${daysToAppointment}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig,
    );
    return resp.data;
  }

  async getSubscriptionRefillsList() {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/subscriptions/refill-dates`;
    const resp = await axios.get(
      apiPath,
      axiosConfig,
    );
    return resp.data;
  }

  async updateNaltrexoneRefill(data) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/subscriptions/refill-updates`;
    const resp = await axios.put(
      apiPath,
      JSON.stringify(data),
      axiosConfig,
    );
    return resp.data;
  }

  async getSalesRepresentative() {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/sales-perosnal/list`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async fetchCustomerByStatus(offset, limit, type = 'pending', query, returnCustomer, sortBy, sortDir, todoItems, todoItemsOperator, currentPack, ketaminePack, genPsychPack, traumaPack, naltrexonePack) {
    const status = type.split('|')[0];
    let subStatus = type.split('|')[1];
    let is_pack_complete = 'false';
    if (subStatus === 'complete') {
      subStatus = null;
      is_pack_complete = 'true';
    }
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/list-by-status`;
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const reqData = {
      status,
      todoItems,
      todoItemsOperator,
      searchValue: query,
      subStatus,
      sortUsersBy: sortBy,
      sortUsersOrder: sortDir,
      offset,
      limit,
      returnCustomers: returnCustomer,
      currentPack,
      is_pack_complete,
    };
    if (ketaminePack) {
      reqData.ketaminePack = ketaminePack;
    }
    if (genPsychPack) {
      reqData.genPsych = genPsychPack;
    }
    if (traumaPack) {
      reqData.traumaPack = traumaPack;
    }
    if (naltrexonePack) {
      reqData.naltrexonePack = naltrexonePack;
    }
    const resp = await axios.post(
      apiPath,
      JSON.stringify(reqData),
      axiosConfig
    );
    return resp.data;
  }

  async updateCustomerStatus(customerId, status, reason) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const resp = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/admin/customers/change-status`,
      {
        user_status: status,
        user_status_reason: reason,
        id: customerId
      },
      axiosConfig
    );
    return resp.data;
  }

  async getBIOrders(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/bi/orders`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getBIFacebook(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/bi/facebook`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomer(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getTodos(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/todos-v3?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async updateTodos(accessToken, customerId, todos) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/todos?customerId=${customerId}`;
    const resp = await axios.post(
      apiPath,
      todos,
      axiosConfig
    );
    return resp.data;
  }

  async getPurchases(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/purchases?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getAllPurchases(params = {}) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);

    // Build query string based on params
    const queryParams = new URLSearchParams();

    // Append only the params that are passed in
    if (params.customerId) queryParams.append('customerId', params.customerId);
    if (params.page) queryParams.append('page', params.page);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params.sortDir) queryParams.append('sortDir', params.sortDir);
    if (params.productType) queryParams.append('productType', params.productType);
    if (params.isInsurancePurchase !== null && params.isInsurancePurchase !== undefined) {
      queryParams.append('isInsurancePurchase', params.isInsurancePurchase.toString());
    }
    if (params.statusReason) queryParams.append('statusReason', params.statusReason);
    if (params.purchaseStatus) queryParams.append('purchaseStatus', params.purchaseStatus);
    if (params.userStatus) queryParams.append('userStatus', params.userStatus);
    if (params.searchValue) queryParams.append('searchValue', params.searchValue);
    if (params.userState) queryParams.append('userState', params.userState);
    if (params.isFirstPurchase !== null && params.isFirstPurchase !== undefined) {
      queryParams.append('isFirstPurchase', params.isFirstPurchase.toString());
    }

    // Construct the API path
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/purchases/list?${queryParams.toString()}`;

    try {
      // Make the API request
      const response = await axios.get(apiPath, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching purchases:', error);
      throw error;
    }
  }

  async updatePurchase(accessToken, purchaseId, update) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/purchases?purchaseId=${purchaseId}`;
    const resp = await axios.post(
      apiPath,
      update,
      axiosConfig
    );
    return resp.data;
  }

  async updatePackComplete(accessToken, purchaseId, update) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/purchases/complete?purchaseId=${purchaseId}`;
    const resp = await axios.post(
      apiPath,
      update,
      axiosConfig
    );
    return resp.data;
  }

  async getPrescriptionTracking(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/prescription-tracking?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerPaymentsSqaureup(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-payments-sqaureup?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSubscriptionsSqaureup(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-subscriptions-sqaureup?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSubscriptionsCancelSqaureup(accessToken, subId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-subscriptions-cancel-sqaureup?subId=${subId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async refundCustomerSquareup(accessToken, customerId, paymentId, amount) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-refunds-sqaureup?customerId=${customerId}&paymentId=${paymentId}&amount=${amount}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async updateCustomer(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers?customerId=${customerId}`;
    const resp = await axios.put(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async updateInsuranceEligibilityDetails(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/insurance?userId=${customerId}`;
    const resp = await axios.put(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerPayments(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/payments?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSubscriptions(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/subscriptions?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async cancelCustomerSubscription(accessToken, subId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/subscriptions/cancel?id=${subId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerTrackings(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/tracking?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomersDocumentsList(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/documents?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerFormsList(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/medical-intake-forms/list?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerFormDetails(accessToken, medicalIntakeId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/medical-intake-form-by-id?medicalIntakeId=${medicalIntakeId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async uploadCustomersDocument(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/documents?customerId=${customerId}`;
    const resp = await axios.post(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getS3SignedDocumentLink(accessToken, documentId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/documents/signed-url?documentId=${documentId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getAppointmentLogs(accessToken, customerId, purchaseId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-logs?customerId=${customerId}&purchaseId=${purchaseId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async deleteAppointmentLogs(accessToken, customerId, logId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-logs?customerId=${customerId}&logId=${logId}`;
    const resp = await axios.delete(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async createAppointmentLogs(accessToken, log) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-logs`;
    const resp = await axios.post(
      apiPath,
      log,
      axiosConfig
    );
    return resp.data;
  }

  async refundCustomer(accessToken, chargeId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/payments/refund?id=${chargeId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async refundCustomerWalnut(accessToken, id) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/payments/walnut-refund?id=${id}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getAllCustomers(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/list?all=true`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async createHsaDenefitsPurchase(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/create-hsa-purchase?customerId=${customerId}`;
    const resp = await axios.post(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }
}

export const customerApi = new CustomerApi();
