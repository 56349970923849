import { Link as RouterLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  useTheme,
  useMediaQuery,
  Box,
  MenuItem,
  Button,
  Menu,
  List,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

import UsersIcon from '../../icons/Users';
import loadingImage from '../../media/ch-white-icon.png';
import CustomLogout from './CustomLogout';
import DrawerComponent from './DrawerComponent';
import { useState } from 'react';
import { ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(0),
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    '&:hover': {
      borderBottom: '1px solid white',
    },
  },
}));

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [customersDrop, setCustomersDrop] = useState(false);
  const [paymentDrop, setPaymentDrop] = useState(false);
  const openCustomersDrop = Boolean(customersDrop);
  const openPaymentDrop = Boolean(paymentDrop);
  const handleCustomersDrop = (event) => {
    setCustomersDrop(event.currentTarget);
  };
  const handlePayment = (event) => {
    setPaymentDrop(event.currentTarget);
  };
  const handleCloseCustomers = () => {
    setCustomersDrop(null);
  };
  const handleClosePayment = () => {
    setPaymentDrop(null);
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <RouterLink
            to="/"
            className={classes.logo}
          >
            <img
              src={loadingImage}
              alt="chooselife"
              height="40"
              width="40"
              display="inline"
            />
          </RouterLink>
        </Box>

        {isMobile ? (
          <DrawerComponent />
        ) : (
          <>
            <div className={classes.navlinks}>
              <Button
                id="basic-button"
                aria-controls={openCustomersDrop ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCustomersDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handleCustomersDrop}
              >
                MANAGEMENT
                {openCustomersDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>

              <Menu
                anchorEl={customersDrop}
                open={openCustomersDrop}
                onClose={handleCloseCustomers}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                style={{ borderRadius: 0 }}
              >
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/purchases"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Purchases"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Customers"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/b2b2c"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="B2B2C"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/ops-view"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Ops Team"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/leads-view"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Sales Team"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/upcoming-insurance-consults"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Insurance Consults"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/upcoming-nax-refills"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Naltrexone Refills"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
              </Menu>

              <Button
                id="basic-button"
                aria-controls={openPaymentDrop ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openPaymentDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handlePayment}
              >
                PAYMENTS
                {openPaymentDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={paymentDrop}
                open={openPaymentDrop}
                onClose={handleClosePayment}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <List
                      component="div"
                      disablePadding
                    >
                      <ListItemButton
                        component={Link}
                        to="/dashboard/nmi/plans"
                      >
                        <ListItemText primary="Plans" />
                      </ListItemButton>
                      <ListItemButton
                        component={Link}
                        to="/dashboard/nmi/coupons"
                      >
                        <ListItemText primary="Coupons" />
                      </ListItemButton>
                      <ListItemButton
                        component={Link}
                        to="/dashboard/orders"
                      >
                        <ListItemText primary="Order" />
                      </ListItemButton>
                      <ListItemButton
                        component={Link}
                        to="/dashboard/collections"
                      >
                        <ListItemText primary="Collections" />
                      </ListItemButton>
                    </List>
                  </List>
                </MenuItem>
              </Menu>
            </div>
            <Box sx={{ ml: 2 }}>
              <CustomLogout />
            </Box>
          </>
        )}
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
